import {DefaultService} from "@/services/DefaultService";
import API from "@/plugins/axios";

class SolicitudService extends DefaultService {

  constructor() {
    super('solicitudes');
  }
  
  getById(solicitud: number | string): Promise<any> {
    return API.get(`solicitudes/${solicitud}`);
  }

  changeStatus(solicitud: number, data: any): Promise<any> {
    return API.post(`solicitudes/${solicitud}/changestatus`, data);
  }

  getAllByRol(role: any, filters: any): Promise<any>{
    let query = new URLSearchParams(filters).toString();
    return API.get('solicitudes/'+role+'/getByRol' + '?' + query);
  }

  descargarFormato(url: any, data: any, type: any, name: string){
    return API.request({
      url: url,
      data: data,
      method: type,
      responseType: 'blob'
    });
  }

  deleteFiles(archivo: number,id: any = null ): Promise<any>{
    return API.post(`solicitudes/${archivo}/${id}/deleteFile`);
  }

  ValidarSoli(solicitud: any): Promise<any>{
    return API.post(`/ValidarSoli`,solicitud);
  }

  RechazarSoli(data: any): Promise<any>{
    return API.post(`/RechazarSoli`,data);
  }

  updateAprobar(data: any): Promise<any>{
    return API.post(`/solicitudes/aprobar`,data);
  }

  checkPago(data: any): Promise<any>{
    return API.post(`/solicitudes/checkpago`,data);
  }
  FechaPago(data: any): Promise<any>{
    return API.post(`/solicitudes/FechaPago`,data);
  }
  obtenerEstadisticas(solicitud: any): Promise<any>{
    return API.post(`/obtenerEstadisticas`,solicitud);
  }
  ValidarPagoOPB(folio: any): Promise<any>{
    return API.post(`/ValidaPagoEfectuado`,folio);
  }

  ReporteDidsa(): Promise<any>{
    return API.get(`/reporte-didsa`);
  }

  ReporteDidsac(): Promise<any>{
    return API.get(`/reporte-didsac`);
  }
}

export default new SolicitudService();