
import Vue from 'vue';
import UserService from '@/services/UserService';
import {mapGetters} from "vuex";
import RULES from "@/mixins/rules";
import LoginService from '@/services/LoginService';
import { VForm } from "@/types/formvalidate";


export default Vue.extend({
    name: 'Auxiliares',
    mixins: [RULES],
    components: {

    },
    data: () => ({
        dialogViewPatente: false,
        documento: null,
        formSearch: null,
        expanded: [],
        list: [],
        dialog: false,
        formRechazo: {
            id: '',
            motivo_rechazo: '',
        },
        dialogNoti: false,
        dialogFilter: false,
        dialogCreateAux:false,
        dialogEditAux:false,
        readOnly:false,
        headers: [
            { text: 'NOMBRE COMPLETO', align: 'center', sortable: false, value: 'nombre' },
            { text: 'CORREO', align: 'start', sortable: false, value: 'email' },
            { text: 'RFC', align: 'start', sortable: false, value: 'rfc' },
            { text: 'ESTATUS', value: 'ids', align: 'start', sortable: false },
            { text: 'ACCIONES', value: 'actions', sortable: false },
        ],
        formAux:{
          id:0,
          nombre:'',
          primer_apellido:'',
          segundo_apellido:'',
          rfc:'',
          email:'',
          password:'',
          activo:false,
        },
        filter: [],
        filtrado:0,
        formFilter: [],
        showPassword:false,
        formNotificacion: {
            message: '',
            establecimientoId: 0,
            notarioId: 0,
            operativoId: 0,
            rfc: '',
            nombre: '',
        },
    }),

    computed: {
        form(): VForm {
            return this.$refs.formRechazo as VForm;
        },
        formAux(): VForm {
          return this.$refs.formAux as VForm;
        },
    ...mapGetters({
            usuario: 'usuario', modules: 'modules' , email: 'email', role: "role", principal: "principal"
        }),
    },

    watch: {},
    mounted() {
        this.getAll();
        this.getStatus();
    },
    methods: {
        async getAll() {
            let dato = {statusNotario: this.formFilter, search: this.formSearch}
            await UserService.getAllData(dato).then(
                (response) => {
                    this.list = response.data.data;
                }
            );
        },
      async storeAux() {
          await UserService.crearAuxiliar(this.formAux).then(
              (response) => {
                if(response.data.success){
                  this.getAll();
                  this.formAux.id = 0;
                  this.formAux.nombre = '';
                  this.formAux.primer_apellido = '';
                  this.formAux.segundo_apellido = '';
                  this.formAux.rfc = '';
                  this.formAux.email = '';
                  this.formAux.password = '';
                  this.formAux.activo = false;
                  this.dialogCreateAux = false;
                  this.$toast.success(response.data.message);
                }else{
                  this.$toast.error(response.data.message);
                }
              }
          );
      },
      async editAux(){
        //@ts-ignore
        await UserService.editAuxiliar(this.formAux.id, this.formAux).then(
            (response) => {
              if(response.data.success){
                this.getAll();
                this.formAux.id = 0;
                this.formAux.nombre = '';
                this.formAux.primer_apellido = '';
                this.formAux.segundo_apellido = '';
                this.formAux.rfc = '';
                this.formAux.email = '';
                this.formAux.password = '';
                this.formAux.activo = false;
                this.dialogEditAux = false;
                this.$toast.success(response.data.message);
              }else{
                this.$toast.error(response.data.message);
              }
            }
        );
      },
      //@ts-ignore
      readEditAux(item:any,ReadOnly){
          this.readOnly = ReadOnly;
          this.dialogEditAux = true
          this.formAux.id = item.id;
          this.formAux.nombre = item.nombre;
          this.formAux.primer_apellido = item.primer_apellido;
          this.formAux.segundo_apellido = item.segundo_apellido;
          this.formAux.rfc = item.rfc;
          this.formAux.email = item.email;
          this.formAux.activo = item.activo;
      },
      //@ts-ignore
        setLinea(item) {
          let lineas = '';
          //@ts-ignore
          item.user_puerto.forEach(item2 => {
            //@ts-ignore
            item2.puerto.linea_nav.forEach( item3 => {
              lineas += item3.nombre + ' , ';
            })
          })
          return lineas;
        },
        async getStatus(){
            await UserService.getStatus().then(
                (response) => {
                    this.filter = response.data.data;
                }
            );
        },
        
        // @ts-ignore
        async descargarPdf(item) {
            await UserService.descargarFormato(`notarioFile/${item}`,{},'GET','Temp').then(
                (response) => {
                    let file = new Blob([response.data],{type: response.headers['content-type']});
                    const url = window.URL.createObjectURL(file);
                    const tipoArchivo = 'test';
                    //@ts-ignore
                    this.documento = {
                    title: tipoArchivo,
                    url: url,
                    mime: response.headers['content-type']
                    }
                    this.dialogViewPatente = true;
                }
            );
        },
        // @ts-ignore
        async Validar(item){
                await UserService.setValidar(item).then(
                (response) => {
                    if(response.data.success){
                        this.$toast.success(response.data.message);
                    }else{
                        this.$toast.error(response.data.message);
                    }
                  this.getAll();
                }
            );
            console.log(item)
        },
        // @ts-ignore
        async RechazarDialog(item){
            this.formRechazo.id = item;
            this.dialog = true;
            console.log(this.formRechazo);
        },
        // @ts-ignore
        async RechazarEnvio(){
            if(this.form.validate()){
                await UserService.setRechazar(this.formRechazo).then(
                    (response) => {
                        if(response.data.success){
                            this.dialog = false;
                            this.$toast.success(response.data.message);
                        }else{
                            this.$toast.error(response.data.message);
                        }
                      this.getAll();
                    }
                );
            }
        },

        async ReadOnly(id: string,edit: boolean) {
          //@ts-ignore
            await this.$router.push({name: 'FormReadOnly', params: { userId: id, editar: edit} });
        },
        // @ts-ignore
        dialogNotification(item){
            this.formNotificacion.notarioId = item,
            this.dialogNoti = true
        },
        sendNotification() {
            // 20 licencias, 6 usoSuelo, 7 anuencia, 20 residuosSolidos
            const role = this.$store.getters.role;

            this.formNotificacion.rfc = this.$store.getters.rfc;
            this.formNotificacion.operativoId = this.$store.getters.id;
            this.formNotificacion.nombre = this.$store.getters.usuario;
            if (this.formNotificacion.message !== '') {
                LoginService.sendNotification(role, this.formNotificacion).then((response) => {
                    if (response.data.success) {
                        this.dialogNoti = false;
                        this.formNotificacion = {
                            message: '',
                            notarioId: 0,
                            operativoId: 0,
                            establecimientoId: 0,
                            rfc: '',
                            nombre: '',
                        };
                        this.$toast.success(response.data.message);
                    } else {
                        this.$toast.error(response.data.message);
                    }
                })
            } else {
                this.$toast.error('Por favor, llene todos los campos');
            }
        }
    },
})
