import Vue from 'vue';
import VueRouter,{ RouteConfig  } from 'vue-router'
// @ts-ignore
import VueRouteMiddleware from 'vue-route-middleware'
// @ts-ignore
import AuthMiddleware from '@/router/middleware/auth';
// @ts-ignore
import RedirectMiddleware from '@/router/middleware/redirect';
// @ts-ignore
import AllowMiddleware from '@/router/middleware/allow-role';

import Full from '@/containers/Full.vue';
import Dashboard from '@/views/Dashboard.vue'
import Calendario from '@/views/Calendario.vue'
import Usuarios from '@/views/Usuarios.vue'
import Perfil from '@/views/Perfil.vue'
import FullLogin from '@/containers/FullLogin.vue'
import Login from '@/views/auth/Login.vue'
import SignUp from '@/views/auth/SignUp.vue'
import RecoveryPassword from '@/views/auth/RecoveryPassword.vue';
import RequestsContainer from '@/containers/Requests/index.vue';
import Uma from '@/views/Catalogos/Uma/index.vue';
import TipoOperacion from "@/views/Catalogos/TipoOperacion/index.vue";
import TipoPersona from "@/views/Catalogos/TipoPersona/index.vue";
import TaxPayer from "@/components/TaxPayer/index.vue";
import ContribuyentesIndex from '@/components/Contribuyentes/index.vue';
import ContribuyenteReadOnly from '@/components/Contribuyentes/FormReadOnly.vue';
import INPC from "@/views/Catalogos/INPC/index.vue";
import NotarioDashboard from "@/views/Dashboards/NotarioDashboard.vue";
import FormUpdate from "@/views/Notarios/FormUpdate.vue";
import Notarias from "@/views/Catalogos/Notarias/index.vue"
import Subsidios from "@/views/Catalogos/Subsidios/index.vue"
import TasaImpuestos from "@/views/Catalogos/TasaImpuestos/index.vue";
import TasaRecargos from "@/views/Catalogos/TasaRecargos/index.vue";
//didsa
import Didsa from "@/components/Didsa/index.vue";
import FormDidsa from "@/components/Didsa/Formdidsa.vue";
import FromDidsaReadOnly from "@/components/Didsa/FromDidsaReadOnly.vue";
import DidsaC from "@/components/DidsaC/index.vue";
import FormDidsaC from "@/components/DidsaC/FormdidsaC.vue";
import FormDidsaCReadOnly from "@/components/DidsaC/FormDidsaCReadOnly.vue";
import SolicitudesDidsacs from "@/components/SolicitudesDidsacs/index.vue";
import Pagos from "@/components/Pagos/index.vue";
import auxiliares from "@/components/Auxiliares/index.vue";
import Auxiliares from "@/components/Auxiliares/index.vue";
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'FullLogin',
        redirect: '/signin',
        component: FullLogin,
        meta: {
            middleware: [ RedirectMiddleware ]
        },
        children: [
            {
                path: 'signin',
                name: 'LoginForm',
                component: Login,
            },
            {
                path: 'signup',
                name: 'SignUp',
                component: SignUp,
            },
            {
                path: 'recoveryPassword',
                name: 'RecoveryPassword',
                component: RecoveryPassword,
            },
        ]
    },
    {
        path: '/admin',
        name: 'Full',
        redirect: '/admin/dashboard',
        component: Full,
        children: [
            {
                path: 'dashboard',
                name: 'dashboard',
                component: Dashboard,
                meta: {
                    middleware: [ AuthMiddleware, AllowMiddleware ]
                }
            },
            {
                path: 'estadisticas',
                name: 'metricas',
                component: NotarioDashboard,
                meta: {
                    middleware: [ AuthMiddleware, AllowMiddleware ]
                }
            },
            {
                path: 'calendario',
                name: 'calendario',
                component: Calendario,
                meta: {
                    middleware: [ AuthMiddleware ]
                }
            },
            {
                path: 'didsa',
                name: 'didsa',
                component: Didsa,
                meta: {
                    middleware: [ AuthMiddleware ]
                }
            },
            {
                path: 'formdidsa',
                name: 'formdidsa',
                component: FormDidsa,
                meta: {
                    middleware: [ AuthMiddleware ]
                }
            },
            {
                path: 'formdidsareadonly/readonly/:didsaId',
                name: 'formdidsareadonly',
                component: FromDidsaReadOnly,
                meta: {
                    middleware: [ AuthMiddleware ]
                }
            },
            {
                path: 'didsac',
                name: 'didsac',
                component: DidsaC,
                meta: {
                    middleware: [ AuthMiddleware ]
                }
            },
            {
                path: 'formdidsac',
                name: 'formdidsac',
                component: FormDidsaC,
                meta: {
                    middleware: [ AuthMiddleware ]
                }
            },
            {
                path: 'formdidsacreadonly/readonly/:didsaId',
                name: 'formdidsacreadonly',
                component: FormDidsaCReadOnly,
                meta: {
                    middleware: [ AuthMiddleware ]
                }
            },
            {
                path: 'solicitudesDidsa',
                name: 'solicitudesDidsa',
                component: SolicitudesDidsacs,
                meta: {
                    middleware: [ AuthMiddleware ]
                }
            },
            {
                path: 'usuarios',
                name: 'usuarios',
                component: Usuarios,
                meta: {
                    middleware: [ AuthMiddleware ]
                }
            },
            {
                path: 'perfil',
                name: 'perfil',
                component: Perfil,
                meta: {
                    middleware: [ AuthMiddleware ]
                }
            },
            {
                path: 'catalogos',
                redirect: 'catalogos/entregables',
                name: 'Catalogos',
                meta: {
                    middleware: [ AuthMiddleware ]
                },
                component: {
                    render(c: any) {
                        return c('router-view');
                    }
                },
            },
            {
                path: 'catalogos',
                name: 'catalogos',
                component: RequestsContainer,
                meta: {
                    middleware: [ AuthMiddleware ]
                },
                children: [
                    {
                        path: 'notaria',
                        name: 'notaria',
                        component: Notarias,
                    },
                    {
                        path: 'subsidios',
                        name: 'subsidios',
                        component: Subsidios,
                    },
                    {
                        path: 'tasaimpuestos',
                        name: 'tasaimpuestos',
                        component: TasaImpuestos,
                    },
                    {
                        path: 'tasarecargos',
                        name: 'tasarecargos',
                        component: TasaRecargos,
                    },
                    {
                        path: 'tipoOperacion',
                        name: 'tipoOperacion',
                        component: TipoOperacion,
                    },
                    {
                        path: 'tipoPersona',
                        name: 'tipoPersona',
                        component: TipoPersona,
                    },
                    {
                        path: 'uma',
                        name: 'uma',
                        component: Uma,
                    },
                    {
                        path: 'inpc',
                        name: 'inpc',
                        component: INPC,
                    },
                ]
            },
            {
                path: 'notarias',
                name: 'notarias',
                component: TaxPayer,
                meta: {
                    middleware: [ AuthMiddleware ]
                }
            },
            {
                path: 'contribuyente',
                name: 'notario',
                component: FormUpdate,
                meta: {
                    middleware: [ AuthMiddleware ]
                }
            },
            {
                path: 'contribuyentes',
                name: 'notarios',
                component: ContribuyentesIndex,
                meta: {
                    middleware: [ AuthMiddleware ]
                }
            },
            {
                path: 'contribuyentes/readonly/:userId',
                name: 'FormReadOnly',
                component: ContribuyenteReadOnly,
                meta: {
                    middleware: [ AuthMiddleware ]
                }
            },
            {
                path: 'pagosdidsa',
                name: 'pagosdidsa',
                component: Pagos,
                meta: {
                    middleware: [ AuthMiddleware ]
                }
            },
            {
                path: 'auxiliares',
                name: 'auxiliares',
                component: Auxiliares,
                meta: {
                    middleware: [ AuthMiddleware ]
                }
            },
        ]
    },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(VueRouteMiddleware());

export default router
